import React from 'react'
import { node, string, bool } from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

// Since DOM elements <Link> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, href, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(href)

  // Use Gatsby Link for internal links, and <Link> for others
  if (internal) {
    return (
      <GatsbyLink
        to={href}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={href} {...other} rel='noreferrer noopener'>
      {children}
    </a>
  )
}

Link.propTypes = {
  children: node,
  href: string,
  activeClassName: string,
  partiallyActive: bool
}

export default Link
