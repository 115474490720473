import React, { Component } from 'react'
import { node, string, bool } from 'prop-types'
import { Helmet } from 'react-helmet'
import Link from './anchor'

class BluHeader extends Component {
  constructor () {
    super()
    this.state = {
      open: false
    }
  }

  render () {
    return (
      <div
        id='desktop-nav'
        data-collapse='medium'
        data-animation='default'
        data-duration={400}
        data-doc-height={1}
        className='navbar blue w-nav'
      >
        <div className='container nav'>
          <Link href='/' className='brand-header w-nav-brand'>
            <img src='/images/url-shortener.svg' alt='Url shortener' className='logo-header' />
          </Link>
          <div className={`w-nav-button ${this.state.open ? 'w--open' : ''}`} onClick={() => this.setState({ open: !this.state.open })}>
            <div className='menu-icon-white w-icon-nav-menu' />
          </div>
          <div className='nav-menu-left w-hidden-medium w-hidden-small'>
            <Link href='/features' className='nav-link-light w-nav-link'>
              Features
            </Link>
            <Link
              href='https://app.rebrandly.com/apps'
              rel='noopener noreferrer'
              className='nav-link-light w-nav-link'
            >
              Integrations
            </Link>
            <Link href='/pricing' className='nav-link-light w-nav-link'>
              Pricing
            </Link>
            <Link href='/enterprise' className='nav-link-light w-nav-link'>
              Enterprise
            </Link>
            <Link href='/jobs' className='nav-link-light w-nav-link'>
              Join our team
            </Link>
            <Link href='/about' className='nav-link-light w-nav-link'>
              About
            </Link>
          </div>
          <nav role='navigation' className='nav-menu w-nav-menu'>
            <span className='w-hidden-main'>
              <Link href='features' className='nav-link w-nav-link'>
                Features
              </Link>
              <Link
                href='https://app.rebrandly.com/newdomain'
                className='nav-link w-nav-link'
              >
                Domains
              </Link>
              <Link href='pricing' className='nav-link w-nav-link'>
                Pricing
              </Link>
              <Link href='enterprise' className='nav-link w-nav-link'>
                Enterprise
              </Link>
            </span>
            <div className='nav-menu-right'>
              <Link
                data-login
                href='https://app.rebrandly.com/login'
                target='_blank'
                rel='noopener noreferrer'
                className='btn-secondary btn-no-border btn-small btn-nav white w-button'
              >
                Login
              </Link>
              <Link
                data-changer
                id='btn-nav-large-pad'
                href='https://app.rebrandly.com/contact-us'
                target='_blank'
                rel='noopener noreferrer'
                className='btn-secondary btn-small white btn-nav w-button'
              >
                Get a quote
              </Link>
            </div>
          </nav>
        </div>
        <div className={`w-nav-overlay ${this.state.open ? 'w-nav-overlay-open' : ''}`}>
          <nav role='navigation' className={`nav-menu w-nav-menu ${this.state.open ? 'w--nav-menu-open' : ''}`}>
            <span className='w-hidden-main'>
              <a href='https://www.rebrandly.com/features' className='nav-link w-nav-link w--nav-link-open'>Features</a>
              <a href='https://app.rebrandly.com/newdomain' className='nav-link w-nav-link w--nav-link-open'>Domains</a>
              <a href='https://www.rebrandly.com/pricing' className='nav-link w-nav-link w--nav-link-open'>Pricing</a>
              <a href='https://www.rebrandly.com/enterprise' className='nav-link w-nav-link w--nav-link-open'>Enterprise</a>
            </span>
            <div className='nav-menu-right'>
              <a data-login='' href='https://app.rebrandly.com/login' target='_blank' className='btn-secondary btn-no-border btn-small btn-nav w-button' rel='noopener noreferrer'>Login</a>
              <a data-signup='' href='https://app.rebrandly.com/sign-up' target='_blank' className='btn-secondary btn-no-border btn-small btn-nav w-button' rel='noopener noreferrer'>Sign up</a>
              <a data-changer='' id='btn-nav-large-pad' href='https://app.rebrandly.com/contact-us' target='_blank' className='btn-secondary btn-small btn-nav w-button' rel='noopener noreferrer'>Get a quote</a>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

const SmsHeader = () => (
  <div>
    <div id='mobile-nav' />
    <div
      id='desktop-nav'
      data-collapse='none'
      data-animation='default'
      data-duration={400}
      data-doc-height={1}
      className='navbar blue navbar-dark light w-nav'
    >
      <div className='container nav'>
        <Link href='/' className='brand-header w-nav-brand'>
          <img
            src='/images/URL-Shortener.fileextension.svg'
            alt='image' className='logo-header'
          />
          <div className='logo-section-text dark'>| &nbsp;SMS</div>
        </Link>
        <div className='menu-button-dark dark-modificator w-nav-button'>
          <div className='menu-icon-white w-icon-nav-menu' />
        </div>
        <nav role='navigation' className='nav-menu-blue w-nav-menu'>
          <Link
            href='https://app.rebrandly.com/pricing/contact-us'
            target='_blank'
            className='btn-primary btn-small btn-nav hidden w-button'
          >
            Contact sales
          </Link>
        </nav>
      </div>
    </div>
  </div>
)

class DefaultHeader extends Component {
  constructor () {
    super()
    this.state = {
      open: false
    }
  }

  render () {
    return (
      <div
        id='desktop-nav'
        data-collapse='medium'
        data-animation='default'
        data-duration='400'
        className='navbar white w-nav'
      >
        <div className='container nav'>
          <Link href='/' className='brand-header w-nav-brand w--current'>
            <img
              src='/images/URL-Shortener.fileextension.svg'
              height='38'
              alt='URL Shortener and Link Shortener | Rebrandly Logo'
              title='Link Shortener and URL Shortener | Rebrandly Logo'
              className='logo-header'
            />
          </Link>
          <div className={`menu-button w-nav-button ${this.state.open ? 'w--open' : ''}`} onClick={() => this.setState({ open: !this.state.open })}>
            <div className='menu-icon blue w-icon-nav-menu' />
          </div>
          <div className='nav-menu-left home w-hidden-medium w-hidden-small'>
            <Link href='/features' className='nav-link w-nav-link'>
              Features
            </Link>
            <Link
              href='https://app.rebrandly.com/newdomain'
              className='nav-link w-nav-link'
            >
              Domains
            </Link>
            <Link href='/pricing' className='nav-link w-nav-link'>
              Pricing
            </Link>
            <Link href='/enterprise' className='nav-link w-nav-link'>
              Enterprise
            </Link>
          </div>
          <nav role='navigation' className='nav-menu w-nav-menu'>
            <span className='w-hidden-main'>
              <Link href='/features' className='nav-link w-nav-link'>
                Features
              </Link>
              <Link
                href='https://app.rebrandly.com/newdomain'
                className='nav-link w-nav-link'
              >
                Domains
              </Link>
              <Link href='/pricing' className='nav-link w-nav-link'>
                Pricing
              </Link>
              <Link href='/enterprise' className='nav-link w-nav-link'>
                Enterprise
              </Link>
            </span>
            <div className='nav-menu-right'>
              <Link
                data-login
                href='https://app.rebrandly.com/login'
                target='_blank'
                rel='noopener noreferrer'
                className='btn-secondary btn-no-border btn-small btn-nav w-button'
              >
                Login
              </Link>
              <Link
                data-signup
                href='https://app.rebrandly.com/sign-up'
                target='_blank'
                rel='noopener noreferrer'
                className='signup-btn btn-secondary btn-no-border btn-small btn-nav w-button'
              >
                Sign up
              </Link>
              <Link
                data-changer
                id='btn-nav-large-pad'
                href='https://app.rebrandly.com/contact-us?ref=Website-Link-Get-Quote-Header'
                target='_blank'
                rel='noopener noreferrer'
                className='btn-secondary btn-small btn-nav w-button'
              >
                Get a quote
              </Link>
            </div>
          </nav>
        </div>
        <div className={`w-nav-overlay ${this.state.open ? 'w-nav-overlay-open' : ''}`}>
          <nav role='navigation' className={`nav-menu w-nav-menu ${this.state.open ? 'w--nav-menu-open' : ''}`}>
            <span className='w-hidden-main'>
              <a href='https://www.rebrandly.com/features' className='nav-link w-nav-link w--nav-link-open'>Features</a>
              <a href='https://app.rebrandly.com/newdomain' className='nav-link w-nav-link w--nav-link-open'>Domains</a>
              <a href='https://www.rebrandly.com/pricing' className='nav-link w-nav-link w--nav-link-open'>Pricing</a>
              <a href='https://www.rebrandly.com/enterprise' className='nav-link w-nav-link w--nav-link-open'>Enterprise</a>
            </span>
            <div className='nav-menu-right'>
              <a data-login='' href='https://app.rebrandly.com/login' target='_blank' className='btn-secondary btn-no-border btn-small btn-nav w-button' rel='noopener noreferrer'>Login</a>
              <a data-signup='' href='https://app.rebrandly.com/sign-up' target='_blank' className='btn-secondary btn-no-border btn-small btn-nav w-button' rel='noopener noreferrer'>Sign up</a>
              <a data-changer='' id='btn-nav-large-pad' href='https://app.rebrandly.com/contact-us' target='_blank' className='btn-secondary btn-small btn-nav w-button' rel='noopener noreferrer'>Get a quote</a>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

const DarkHeader = () => (
  <div
    id='desktop-nav'
    data-collapse='none'
    data-animation='default'
    data-duration={400}
    data-doc-height={1}
    className='navbar blue navbar-dark dark-modificator w-nav'
  >
    <div className='container nav'>
      <Link href='/' className='brand-header w-nav-brand'>
        <img src='/images/url-shortener.svg' alt='Url shortener' className='logo-header' />
        <div className='logo-section-text w-hidden-small w-hidden-tiny'>
          | &nbsp;Enterprise
        </div>
        <div className='logo-section-text no-pipe w-hidden-medium w-hidden-main'>
          Enterprise
        </div>
      </Link>
      <div className='menu-button-dark dark-modificator w-nav-button'>
        <div className='menu-icon-white w-icon-nav-menu' />
      </div>
      <nav role='navigation' className='tts nav-menu-blue w-nav-menu'>
        <span className='tts-copy w-hidden-tiny'>
          Talk to Sales: +1 (415) 702-2047
        </span>
        <span className='tts-copy w-hidden-small w-hidden-medium w-hidden-main'>
          Talk to Sales
          <br />
          +1 (415) 702-2047
        </span>
      </nav>
    </div>
  </div>
)

const LinkGalleryHeader = () => (
  <div
    id='desktop-nav'
    data-collapse='none'
    data-animation='default'
    data-duration={400}
    data-doc-height={1}
    className='navbar blue navbar-dark light w-nav'
  >
    <div className='container nav'>
      <Link href='/' className='brand-header w-nav-brand'>
        <img
          src='/images/link-gallery-logo.svg'
          width={426}
          className='linkgallery-logo logo-header'
        />
      </Link>
      <div className='nav-menu-right'>
        <Link
          data-signup
          href='https://app.rebrandly.com/apps/linkgallery/oauth'
          target='_blank'
          className='signup-btn btn-secondary btn-small btn-nav w-button w-hidden'
        >
          Sign up
        </Link>
      </div>
    </div>
  </div>
)

class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = { isClient: false }
  }

  componentDidMount () {
    this.setState({ isClient: true })
  }

  renderHeader (type = false) {
    switch (type) {
      case false:
        return <DefaultHeader />
      case 'none':
        return null
      case 'sms':
        return <SmsHeader />
      case 'blu':
        return <BluHeader />
      case 'linkgallery':
        return <LinkGalleryHeader />
      case 'dark':
        return <DarkHeader />
      case 'empty':
        return null
    }
  }

  renderAdditionalMeta () {
    if (this.props.noMeta) {
      return `
      <meta
            content='URL Shortener with custom domains. Shorten, brand and track your links with the industry-leading link management platform. Try Rebrandly for free. API, Short URL, Custom Domains.'
            name='description'
          />
          <meta
            content='Rebrandly | URL Shortener, Branded Link Management, API'
            property='og:title'
          />
          <meta
            content='URL Shortener with custom domains. Shorten, brand and track your links with the industry-leading link management platform. Try Rebrandly for free. API, Short URL, Custom Domains.'
            property='og:description'
          />
          <meta content='/open-graph.png' property='og:image' />
          <meta content='summary' name='twitter:card' />
      `
    } else return ''
  }

  render () {
    const { children, type, noMeta } = this.props
    if (noMeta) {
      return (
        <div>
          <Helmet>
            <html lang='en' />
            <meta charset='utf-8' />
            <title>
              Rebrandly | Custom URL Shortener, Branded Link Management, API
            </title>
            <meta name='google-site-verification' content='4_wjl2_6-aIQRAzqg3lLPX9Sa78zxuJ_EbI_L-yOzoE' />
            <meta content='width=device-width, initial-scale=1' name='viewport' />
            <link href='images/rebrandly-webclip.png' rel='apple-touch-icon' />
            <link rel='stylesheet' href='https://use.typekit.net/waw8itp.css' />
            <script src='/js/signup-redirect.min.js' />
            <script>
              {`
              (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, 'script', 'dataLayer', 'GTM-NZVWGS3');
          `}
            </script>
            <noscript>
              {`<iframe src="https://www.googletagmanager.com/ns?id=GTM-NZVWGS3" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
            </noscript>
          </Helmet>
          <div id='mobile-nav' />
          {this.renderHeader(type)}
          <div>
            {children}
          </div>
        </div>
      )
    }
    return (
      <div>
        <Helmet>
          <html lang='en' />
          <meta charset='utf-8' />
          <title>
            Rebrandly | Custom URL Shortener, Branded Link Management, API
          </title>
          <meta name='google-site-verification' content='4_wjl2_6-aIQRAzqg3lLPX9Sa78zxuJ_EbI_L-yOzoE' />
          <meta
            content='The perfect free URL shortener for transforming long, ugly links into nice, memorable and trackable short URLs. Use it to shorten links'
            name='description'
          />
          <meta
            content='Rebrandly | Custom URL Shortener, Branded Link Management, API'
            property='og:title'
          />
          <meta
            content='The perfect free URL shortener for transforming long, ugly links into nice, memorable and trackable short URLs. Use it to shorten links'
            property='og:description'
          />
          <meta content='/open-graph.png' property='og:image' />
          <meta content='summary' name='twitter:card' />
          <meta content='width=device-width, initial-scale=1' name='viewport' />
          <link href='images/rebrandly-webclip.png' rel='apple-touch-icon' />
          <link rel='stylesheet' href='https://use.typekit.net/waw8itp.css' />
          <script src='/js/signup-redirect.min.js' />
          <script>
            {`
              (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, 'script', 'dataLayer', 'GTM-NZVWGS3');
          `}
          </script>
          <noscript>
            {`<iframe src="https://www.googletagmanager.com/ns?id=GTM-NZVWGS3" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
          </noscript>
        </Helmet>
        <div id='mobile-nav' />
        {this.renderHeader(type)}
        <div>
          {children}
        </div>
      </div>
    )
  }
}

Layout.propTypes = {
  children: node,
  type: string,
  noMeta: bool
}

export default Layout
